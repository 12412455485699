import React from "react"

import * as S from "./styled"

const Contato = () => (
  <S.ContatoWrapper>
    <S.ContatoTitle>Contato</S.ContatoTitle>
    <S.ContatoForm>
      <form
        method="POST"
        name="contato"
        data-netlify="true"
        netlify-honeypot="bot-field"
      >
        <S.ContatoInput type="hidden" name="form-name" value="contato" />
        <S.ContatoInputHidden name="bot-field" />
        <S.ContatoInput
          type="text"
          placeholder="Seu nome..."
          name="nome"
          required
        />
        <S.ContatoInput
          type="email"
          placeholder="Email..."
          name="email"
          required
        />
        <S.ContatoInput
          type="text"
          placeholder="Assunto..."
          name="assunto"
          required
        />
        <S.ContatoTextArea
          name="mensagem"
          placeholder="Mensagem..."
          cols="30"
          rows="10"
          required
        ></S.ContatoTextArea>
        <S.ContatoButton type="submit">Enviar</S.ContatoButton>
      </form>
    </S.ContatoForm>
  </S.ContatoWrapper>
)

export default Contato
