import React from "react"

import Layout from "../components/Layout"
import Contato from "../components/Contato"
import SEO from "../components/seo"

import * as S from "../components/Pages/general"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contato"
      description="Ficou alguma dúvida sobre o Método Fan Art 3.0, não deixe de tirá-las, preencha o forumlário e entre em contato agora!"
    />
    <S.GeneralWrapper>
      <Contato />
    </S.GeneralWrapper>
  </Layout>
)

export default ContactPage
